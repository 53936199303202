<template>
  <b-col md="12">
    <b-card class="card-congratulations text-center">
      <b-spinner small v-if="this.loading" />
    </b-card>
  </b-col>
</template>

<script>
  import Ripple from 'vue-ripple-directive'
  import docxtemplater from 'docxtemplater'
  import PizZip from 'pizzip'
  import { saveAs } from 'file-saver'
  import JSZipUtils from 'jszip-utils'
  import useJwt from '@/auth/jwt/useJwt'
  import moment from 'moment'
  import { formatarData, formatarValor, formatarDataCurto, formatarParaNumber } from '@/libs/utils'
  import { PascalCaseComEspaco } from '@/libs/utils'
  import { generateImage } from '@/utils/image-module/image-module.helper.js'
  import axios from 'axios'
  import { checkIcon, blankIcon, agendaIcon, keyIcon } from '@/utils/image-module/check-icon'
  import { encryptNumber } from '@/utils/encryption'
  import { e } from 'mathjs'
  import * as Math from 'mathjs'

  export default {
    components: {
      formatarData,
      formatarValor,
      formatarDataCurto,
      Math,
    },
    directives: {
      Ripple,
    },
    props: {
      parametro: {},
      assinaturas: {},
    },
    data() {
      return {
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        bancarioitems: [],
        primeiraParcela: [],
        ultimaParcela: [],
        loading: false,
        valorContrato: null,
        dadosInstituto: null,
      }
    },
    async mounted() {
      if (this.parametro) {
        this.loading = true
        await this.consultaCliente()
      }
    },
    methods: {
      carregarContrato(item) {
        useJwt
          .get(`financeiro/${item}`)
          .then((response) => {
            let valorTotalContrato = parseFloat('0.00')
            this.contrato = response.data
            if (this.contrato.parcela.length == 0) {
              this.$emit(
                'contratoEvent',
                'error',
                'Não foi possível gerar o contrato, pois não há parcelas cadastradas para este contrato.',
              )
            }
            this.contrato.parcela.forEach((item) => {
              if (item.parcela == this.contrato.numeroParcelas) {
                this.ultimaParcela = item.vencimento
              } else if (item.parcela == '1') {
                this.primeiraParcela = item.vencimento
              }

              valorTotalContrato = valorTotalContrato + formatarParaNumber(item.valor)
            })
            this.valorContrato = valorTotalContrato.toFixed(2)

            const bancoId = this.contrato.proposta.segurado.tomador.bancoId
            if (bancoId) this.carregarBanco(bancoId)
            else this.$message.error('O tomador não possui dados bancários cadastrados corretamente! Favor verificar')
          })
          .catch((error) => {
            this.loading = false
            console.error(error)
          })
      },
      carregarBanco(item) {
        useJwt
          .get(`utils/GetBancos/${item}`)
          .then((response) => {
            this.bancarioitems = response.data
            this.renderDoc(this.parametro)
          })
          .catch((error) => {
            this.loading = false
            console.error(error)
          })
      },
      loadFile(url, callback) {
        JSZipUtils.getBinaryContent(url, callback)
      },
      formatDate(dateString) {
        const date = new Date(dateString)
        return new Intl.DateTimeFormat('pt-BR', {
          dateStyle: 'short',
          timeStyle: 'long',
          timeZone: 'America/Sao_Paulo',
        }).format(date)
      },
      geraAssinaturaTexto(assinou, assinouEm, nome, papel, indice) {
        if (!assinou) {
          return {
            ['signatario' + indice]: '',
            ['signatarioPapel' + indice]: '',
            ['iconeDeCheck' + indice]: blankIcon,
            ['emitidoPor' + indice]: '',
          }
        }

        const safeMomentFormat = (date, format) => (date ? moment(date).format(format) : '')
        const signatario = `${nome}` + ' em ' + `${this.formatDate(assinouEm)}`
        const signatarioPapel = `Papel: ${papel}`

        return {
          ['signatario' + indice]: signatario,
          ['signatarioPapel' + indice]: signatarioPapel,
          ['iconeDeCheck' + indice]: keyIcon,
          ['emitidoPor' + indice]: 'Emitido por: Agenda Bank',
        }
      },
      geraAssinatura(args) {
        const assinouPendente = (assinou, name) => (assinou ? `${name}` : ``) //`Assinatura pendente: ${name}`
        const safeMomentFormat = (date, format) => (date ? moment(date).format(format) : '')

        const tomador = this.contrato.proposta.segurado.tomador
        const testemunha1 = args.testemunhas[0]
        const testemunha2 = args.testemunhas[1]
        const gestor = args.gestores[0]

        const tomadorObj = {
          assinouEm: args?.financeiro?.tomadorAssinouEm ?? '',
          assinou: args?.financeiro?.tomadorAssinou ?? '',
          nome: tomador?.nomeCompleto ?? '',
          papel: 'Tomador',
        }

        const testemunha1Obj = {
          assinouEm: testemunha1?.assinouEm ?? '',
          assinou: testemunha1?.assinouFinanceiro ?? '',
          nome: testemunha1?.usuario?.name ?? '',
          papel: 'Testemunha',
        }

        const testemunha2Obj = {
          assinouEm: testemunha2?.assinouEm ?? '',
          assinou: testemunha2?.assinouFinanceiro ?? '',
          nome: testemunha2?.usuario.name ?? '',
          papel: 'Testemunha',
        }

        const gestorObj = {
          assinouEm: gestor?.assinouEm ?? '',
          assinou: gestor?.assinouFinanceiro ?? '',
          nome: gestor?.usuario?.name ?? '',
          papel: 'Gestor',
        }

        const objs = [tomadorObj, testemunha1Obj, testemunha2Obj, gestorObj].sort((a, b) => {
          if (b.assinou === a.assinou) {
            return new Date(a.assinouEm) - new Date(b.assinouEm)
          }
          return b.assinou - a.assinou
        })

        const values = objs
          .map((obj) => this.geraAssinaturaTexto(obj.assinou, obj.assinouEm, obj.nome, obj.papel, objs.indexOf(obj) + 1))
          .reduce((acc, cur) => ({ ...acc, ...cur }), {})

        return {
          ...values,
          gestor1Nome: gestor?.usuario?.name ?? '',
          testemunha1Nome: testemunha1?.usuario?.name ?? '',
          testemunha2Nome: testemunha2?.usuario?.name ?? '',
          tomador1Nome: tomador?.nomeCompleto ?? '',
        }
      },
      renderDoc(parametro) {
        let tomador = this.contrato.proposta.segurado.tomador

        if (tomador.dadoBancarioFormaDePagamento == 'creditoEmContaPoupanca') {
          tomador.dadoBancarioFormaDePagamento = 'Conta Poupança'
        } else if (tomador.dadoBancarioFormaDePagamento == 'creditoEmContaCorrente') {
          tomador.dadoBancarioFormaDePagamento = 'Conta Corrente'
        } else {
          tomador.dadoBancarioFormaDePagamento = null
        }

        let valorDoContrato = Math.bignumber(formatarParaNumber(this.contrato.proposta.valor))
        let valorDoContratoComIOF = Math.bignumber(formatarParaNumber(this.contrato.proposta.valorComIOF))

        const valorTotalEmprestimo = Number(Math.subtract(valorDoContrato, valorDoContratoComIOF))
        this.valorTotalEmprestimo = Number(
          Math.add(valorTotalEmprestimo, formatarParaNumber(this.contrato.proposta.valorComIOF)),
        )

        var self = this

        let data = {
          nomeTomador: ` ${PascalCaseComEspaco(tomador.nomeCompleto ?? '')}`,
          CPFTomador: `${tomador.cpf == null ? '' : tomador.cpf}`,
          SEXOTomador: `${tomador.sexo == null ? '' : tomador.sexo}`,
          NATtomador: `${PascalCaseComEspaco(tomador.naturalidade ?? '')}`, //Naturalidade do tomador
          DatanascTomador: `${
            tomador.dataNascimento == undefined ? '' : moment(tomador.dataNascimento, 'YYYY-MM-DD').format('DD/MM/YYYY')
          }`, //Data de Nascimento do Tomador
          RGTomador: `${tomador.rgNumero}`,
          OrgaoTomador: `${tomador.rgOrgaoEmissor}`,
          UFrgemitida: `${tomador.rgUF == undefined ? '' : tomador.rgUF}`, //UF do rg
          EstadocivilTomador: `${PascalCaseComEspaco(tomador.estadoCivil ?? '')}`,
          NomePaiTomador: `${PascalCaseComEspaco(tomador.nomePai ?? '')}`,
          NomemaeTomador: `${PascalCaseComEspaco(tomador.nomeMae ?? '')}`,
          LogradouroTomador: `${PascalCaseComEspaco(tomador.logradouroEndereco ?? '')}`,
          Ntomador: `${tomador.numeroEndereco}`, //Número do endereço do Tomador
          CompTomador: `${tomador.complementoEndereco == null ? '' : tomador.complementoEndereco}`, //Complemento do endereço do Tomador
          BairroTomador: `${PascalCaseComEspaco(tomador.bairroEndereco ?? '')}`,
          CidadeTomador: `${PascalCaseComEspaco(tomador.cidadeEndereco ?? '')}`,
          UFtomador: `${tomador.ufEndereco}`,
          CEPTomador: `${tomador.cepEndereco}`,
          TelefoneTomador: `${tomador.telefone}`,
          emailTomador: `${tomador.email?.toLowerCase() ?? ''}`,
          BancoTomador: PascalCaseComEspaco(
            `${this.bancarioitems.LongName || this.bancarioitems.nome}` + ' - ' + `${this.bancarioitems?.codigo.split('-')[0]}`,
          ),
          AgenciaTomador: `${tomador.dadoBancarioAgencia}-${tomador.dadoBancarioAgenciaDV}`,
          ContaCorrenteTomador: `${tomador.dadoBancarioConta}-${tomador.dadoBancarioContaDV}`,
          tipodeconta: `${tomador.dadoBancarioFormaDePagamento == null ? '' : tomador.dadoBancarioFormaDePagamento}`,
          DataRequerimento: `${formatarData(this.contrato.createdAt)}`,
          NumContrato: `${this.contrato.codigo}`,
          TaxadeJurosMensal: `${this.contrato.taxas}`,
          SomaTaxasIPCA: `${((Math.pow(1 + this.contrato.taxas / 100, 12) - 1) * 100).toFixed(4)}`,
          valorBaseCalculo: `${Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(
            this.contrato.proposta.valorBaseCalculo,
          )}`,
          valorTotalEmprestado: `${formatarValor(valorDoContratoComIOF)}`,
          SomaParcelasContrato: `${Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(
            this.contrato.proposta.valorTotalComJuros,
          )}`, //Para declarar o valor total do contrato, deverá ser realizado a soma de todas as parcelas do contrato do tomador
          NumeroParcelas: `${this.contrato.numeroParcelas}`, //quantidade de parcelas
          ValorPrimeiraParcela: `${formatarValor(this.contrato.valorParcela)}`,
          VencimentoPrimeiraParcelas: `${formatarData(this.primeiraParcela)}`,
          VencimentoUltimaParcelas: `${formatarData(this.ultimaParcela)}`,
          NomeInstituto: `${this.dadosInstituto.razaoSocial}`,
          CNPJInstituto: `${this.dadosInstituto.cnpj}`,
          LogradouroInstituto: `${this.dadosInstituto.logradouro}`,
          cidadeinstituto: `${this.dadosInstituto.cidade}`,
          Valordequitacao: '',
          BancoQuitacao: '',
          Saldo: '',
          ValorIOF: `${formatarValor(this.contrato.proposta.valorIOF)}`,
        }

        if (self.assinaturas) {
          data = {
            ...data,
            ...self.geraAssinatura(self.assinaturas),
          }
        }
        const component = this

        this.loadFile(`${parametro.s3url}`, async function (error, content) {
          if (error) {
            console.error(error)
            throw error
          }

          function replaceErrors(key, value) {
            if (value instanceof Error) {
              return Object.getOwnPropertyNames(value).reduce(function (error, key) {
                error[key] = value[key]
                return error
              }, {})
            }
            return value
          }

          function errorHandler(error) {
            if (error.properties && error.properties.errors instanceof Array) {
              const errorMessages = error.properties.errors
                .map(function (error) {
                  return error.properties.explanation
                })
                .join('\n')
              console.error(error)
              // errorMessages is a humanly readable message looking like this :
              // 'The tag beginning with "foobar" is unopened'
            }
            throw error
          }

          var zip = new PizZip(content)

          const encryptedNumber = encryptNumber(parametro.contratoId)
          let fields = {
            cabecalho: data.cabecalho,
            nomeTomador: PascalCaseComEspaco(data.nomeTomador ?? ''),
            CPFTomador: data.CPFTomador,
            SEXOTomador: data.SEXOTomador,
            NATtomador: data.NATtomador, //Naturalidade do tomador
            DatanascTomador: data.DatanascTomador, //Data de Nascimento do Tomador
            RGTomador: data.RGTomador,
            OrgaoTomador: data.OrgaoTomador,
            UFrgemitida: data.UFrgemitida, //UF do rg
            EstadocivilTomador: data.EstadocivilTomador,
            NomePaiTomador: data.NomePaiTomador,
            NomemaeTomador: data.NomemaeTomador,
            LogradouroTomador: data.LogradouroTomador,
            Ntomador: data.Ntomador, //Número do endereço do Tomador
            CompTomador: data.CompTomador, //Complemento do endereço do Tomador
            BairroTomador: data.BairroTomador,
            CidadeTomador: data.CidadeTomador,
            UFtomador: data.UFtomador,
            CEPTomador: data.CEPTomador,
            TelefoneTomador: data.TelefoneTomador,
            emailTomador: data.emailTomador,
            BancoTomador: data.BancoTomador,
            AgenciaTomador: data.AgenciaTomador,
            ContaCorrenteTomador: data.ContaCorrenteTomador,
            tipodeconta: data.tipodeconta,
            DataRequerimento: data.DataRequerimento,
            NumContrato: data.NumContrato,
            TaxadeJurosMensal: data.TaxadeJurosMensal,
            SomaTaxasIPCA: data.SomaTaxasIPCA,
            valorTotalEmprestado: data.valorTotalEmprestado,
            valorBaseCalculo: data.valorBaseCalculo,
            SomaParcelasContrato: data.SomaParcelasContrato, //Para declarar o valor total do contrato, deverá ser realizado a soma de todas as parcelas do contrato do tomador
            NumeroParcelas: data.NumeroParcelas, //quantidade de parcelas
            ValorPrimeiraParcela: data.ValorPrimeiraParcela,
            VencimentoPrimeiraParcelas: data.VencimentoPrimeiraParcelas,
            VencimentoUltimaParcelas: data.VencimentoUltimaParcelas,
            NomeInstituto: data.NomeInstituto,
            CNPJInstituto: data.CNPJInstituto,
            LogradouroInstituto: data.LogradouroInstituto,
            cidadeinstituto: data.cidadeinstituto,
            codigoVerificacao: encryptedNumber,
            linkDeVerificacao: `${window.location.origin}/financeiro/verifica-assinatura/${encryptedNumber}`,
            logoAgenda: agendaIcon,
            Valordequitacao: '',
            BancoQuitacao: '',
            Saldo: '',
            ValorIOF: data.ValorIOF,
          }

          if (component.assinaturas) {
            fields = {
              ...fields,
              ...component.geraAssinatura(component.assinaturas),
            }
          }

          let out = undefined

          const url = (await useJwt.get('financeiro/aws/files/url')).data.url + '/' + parametro.contratoId + '/' + 'contrato'
          try {
            out = await generateImage(
              parametro.s3url,
              fields,
              `${window.location.origin}/financeiro/verifica-assinatura/${encryptedNumber}`,
            )
          } catch (error) {
            // Catch rendering errors (errors relating to the rendering of the template : angularParser throws an error)
            errorHandler(error)
          }

          const formData = new FormData()
          formData.append('userId', parametro.userId)
          formData.append('financeiroId', parametro.contratoId)
          formData.append('file', out, parametro.nomeDocumento)
          formData.append('documentoId', parametro.idDocumento)
          const res = (await useJwt.postDocumento(`financeiro/documentacaoProcessoPdf`, (event) => {}, formData)).data

          const response = await axios({
            url,
            method: 'GET',
            responseType: 'blob',
          })

          const urrl = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = urrl
          link.setAttribute('download', 'contrato.pdf')
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)

          self.$emit('contratoEvent', out)
          self.loading = false
        })
      },
      consultaCliente() {
        return new Promise(async (resolve, reject) => {
          await useJwt
            .get(`cadastro/instituto/${this.parametro.institutoId}`)
            .then((response) => {
              this.dadosInstituto = response.data
              this.carregarContrato(this.parametro.contratoId)
              resolve(true)
            })
            .catch((error) => {
              console.error(error)
              reject(false)
            })
        })
      },
    },
  }
</script>

<style></style>
