import docxtemplater from 'docxtemplater'
import JSZip from 'jszip'
import saveAs from 'file-saver'
import ImageModule from './imagemodule'
import qr from 'qr-image'

export async function generateImage(templateUrl, fields, url) {
  const response = await fetch(templateUrl)
  const content = await response.arrayBuffer()
  const png = qr.imageSync(url, { type: 'png' })

  const data = {
    image: png,
    ...fields,
  }

  const opts = {}

  opts.getImage = function (tagValue, tagName) {
    return tagValue
  }

  opts.centered = false

  opts.getSize = function (img, tagValue, tagName) {
    if (tagName.includes('iconeDeCheck')) {
      return [30, 30]
    }

    const forceWidth = 100
    return [forceWidth, forceWidth] // Assumes a 1:1 aspect ratio for simplicity
  }

  var imageModule = new ImageModule(opts)

  //   //   var zip = new JSZip(content);
  const zip = new JSZip(content)
  var doc = new docxtemplater().loadZip(zip).attachModule(imageModule).compile()

  return doc.resolveData(data).then(function () {
    doc.render()
    const out = doc.getZip().generate({
      type: 'blob',
      mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    })
    saveAs(out, 'contrato.docx')
    return out
  })
}
