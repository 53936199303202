<template>
  <b-row>
    <b-col md="4" class="mt-3">
      <b-input-group class="w-100 mb-2" @click="enviarTermoSms()">
        <b-button class="w-100">Enviar termos de aceite</b-button>
      </b-input-group>

      <b-form-group label="Link do termo" label-for="link">
        <b-input-group class="w-100">
          <b-input-group-prepend>
            <span class="input-group-text"> <feather-icon icon="LinkIcon" size="18" /> </span>
          </b-input-group-prepend>

          <b-form-input id="link" type="text" v-model="termo.link" placeholder="Link de integração" :disabled="true" />

          <b-input-group-prepend title="Copiar" class="cursor-pointer" @click="copiarLink()">
            <span class="input-group-text">
              <feather-icon icon="CopyIcon" size="18" />
            </span>
          </b-input-group-prepend>
        </b-input-group>
      </b-form-group>

      <b-form-group label="Status" label-for="status">
        <b-input-group class="w-100">
          <b-input-group-prepend>
            <span class="input-group-text">
              <feather-icon icon="InfoIcon" size="18" />
            </span>
          </b-input-group-prepend>

          <b-form-input :disabled="true" id="status" type="text" v-model="termo.status" placeholder="Status" />
        </b-input-group>
      </b-form-group>
    </b-col>

    <b-col md="8">
      <h3>Termos</h3>
      <div>
        <div
          v-if="termos.habilitaTermoECondicao"
          @click="downloadTermo(termos.habilitaTermoECondicaoTitulo, termos.habilitaTermoECondicaoTexto)"
          class="tw-flex tw-items-center tw-gap-2 tw-mb-2 tw-p-2 tw-rounded-lg"
          style="border: 1px solid #766d84"
        >
          <p class="tw-bg-[#44845C] tw-m-0 tw-p-4" style="border-radius: 100%">
            <feather-icon icon="DownloadIcon" size="20" class="tw-text-white" />
          </p>
          <h4>{{ termos.habilitaTermoECondicaoTitulo }} - {{ termo.status != 'Aprovado' ? 'Não aceito' : 'Aceito' }}</h4>
        </div>
        <div
          v-if="termos.habilitaTermoECondicaoProposta"
          @click="downloadTermo(termos.habilitaTermoECondicaoPropostaTitulo, termos.habilitaTermoECondicaoPropostaTexto)"
          class="tw-flex tw-items-center tw-gap-2 tw-mb-2 tw-p-2 tw-rounded-lg"
          style="border: 1px solid #766d84"
        >
          <p class="tw-bg-[#44845C] tw-m-0 tw-p-4" style="border-radius: 100%">
            <feather-icon icon="DownloadIcon" size="20" class="tw-text-white" />
          </p>
          <h4>
            {{ termos.habilitaTermoECondicaoPropostaTitulo }} - {{ termo.status != 'Aprovado' ? 'Não aceito' : 'Aceito' }}
          </h4>
        </div>
        <div
          v-if="termos.habilitaAceiteDeMargem"
          @click="downloadTermo(termos.habilitaAceiteDeMargemTitulo, termos.habilitaAceiteDeMargemTexto)"
          class="tw-flex tw-items-center tw-gap-2 tw-mb-2 tw-p-2 tw-rounded-lg"
          style="border: 1px solid #766d84"
        >
          <p class="tw-bg-[#44845C] tw-m-0 tw-p-4" style="border-radius: 100%">
            <feather-icon icon="DownloadIcon" size="20" class="tw-text-white" />
          </p>
          <h4>{{ termos.habilitaAceiteDeMargemTitulo }} - {{ termo.status != 'Aprovado' ? 'Não aceito' : 'Aceito' }}</h4>
        </div>
        <div
          v-if="termos.habilitaPoliticaDePrivacidade"
          @click="downloadTermo(termos.habilitaPoliticaDePrivacidadeTitulo, termos.habilitaPoliticaDePrivacidadeTexto)"
          class="tw-flex tw-items-center tw-gap-2 tw-mb-2 tw-p-2 tw-rounded-lg"
          style="border: 1px solid #766d84"
        >
          <p class="tw-bg-[#44845C] tw-m-0 tw-p-4" style="border-radius: 100%">
            <feather-icon icon="DownloadIcon" size="20" class="tw-text-white" />
          </p>
          <h4>{{ termos.habilitaPoliticaDePrivacidadeTitulo }} - {{ termo.status != 'Aprovado' ? 'Não aceito' : 'Aceito' }}</h4>
        </div>
      </div>
    </b-col>
  </b-row>
</template>
<script>
  import useJwt from '@/auth/jwt/useJwt'
  import { formatarDataHora } from '@/libs/utils'
  import html2pdf from 'html2pdf.js'
  export default {
    props: {
      parametro: Object,
    },

    mounted() {
      this.carregarTermos()
      this.termo.link = `${window.location.origin}/termo?dataparam=${this.parametro.segurado.tomadorId}&datacode=${this.parametro.codigo}`
    },
    data() {
      return {
        termo: {
          link: '',
          status: this.parametro.SituacaoTermoProposta.status,
        },
        termos: {},
        SituacaoTermoProposta: this.parametro.SituacaoTermoProposta,
      }
    },
    methods: {
      copiarLink() {
        navigator.clipboard
          .writeText(this.termo.link)
          .then(() => {
            this.$message.success('Link copiado com sucesso!')
          })
          .catch((err) => {
            this.$bvToast.toast('Falha ao copiar o link', {
              title: 'Erro',
              variant: 'danger',
              solid: true,
            })
          })
      },
      async carregarTermos() {
        const termosResponse = (await useJwt.get(`produto/get-termos/${this.parametro.produtoId}`)).data
        // Inicia o objeto vazio
        this.termos = {}

        // Adiciona os campos dinamicamente, apenas se o habilitador for true
        if (termosResponse.habilitaTermoECondicao) {
          this.termos.habilitaTermoECondicao = true
          this.termos.habilitaTermoECondicaoTitulo = termosResponse.habilitaTermoECondicaoTitulo
          this.termos.habilitaTermoECondicaoTexto = termosResponse.habilitaTermoECondicaoTexto
        }

        if (termosResponse.habilitaTermoECondicaoProposta) {
          this.termos.habilitaTermoECondicaoProposta = true
          this.termos.habilitaTermoECondicaoPropostaTitulo = termosResponse.habilitaTermoECondicaoPropostaTitulo
          this.termos.habilitaTermoECondicaoPropostaTexto = termosResponse.habilitaTermoECondicaoPropostaTexto
        }

        if (termosResponse.habilitaAceiteDeMargem) {
          this.termos.habilitaAceiteDeMargem = true
          this.termos.habilitaAceiteDeMargemTitulo = termosResponse.habilitaAceiteDeMargemTitulo
          this.termos.habilitaAceiteDeMargemTexto = termosResponse.habilitaAceiteDeMargemTexto
        }

        if (termosResponse.habilitaPoliticaDePrivacidade) {
          this.termos.habilitaPoliticaDePrivacidade = true
          this.termos.habilitaPoliticaDePrivacidadeTitulo = termosResponse.habilitaPoliticaDePrivacidadeTitulo
          this.termos.habilitaPoliticaDePrivacidadeTexto = termosResponse.habilitaPoliticaDePrivacidadeTexto
        }
      },
      downloadTermo(titulo, texto) {
        if (this.termo.status == 'Pendente') return
        const termoHtml = `
        <div style="min-height: 100vh; display: flex; flex-direction: column; justify-content: space-between;">
          <div>
            <h1 class='tw-text-[#44845C] tw-text-2xl'>${titulo}</h1>
            <h4>${texto}</h4>
          </div>

          <footer style="border-top: 1px solid #ccc; font-size: 12px; margin-top: auto;">
            <p style='margin-top: 16px'>Assinado por: ${this.parametro.segurado.tomador.nomeCompleto}</p>
            <p>Data e hora do aceite: ${formatarDataHora(this.SituacaoTermoProposta.updatedAt)}</p>
            <p>Endereço IP: ${this.SituacaoTermoProposta.ip ? this.SituacaoTermoProposta.ip : 'IP não disponível'}</p>
            <p>Navegador utilizado: ${
              this.SituacaoTermoProposta.navegadorUtilizado
                ? this.SituacaoTermoProposta.navegadorUtilizado
                : 'Navegador não disponível'
            }</p>
            <p>Localização (baseada no IP): ${
              this.SituacaoTermoProposta.localizacao ? this.SituacaoTermoProposta.localizacao : 'Localização não disponível'
            }</p>
          </footer>
        </div>
      `

        // Inserir o HTML no DOM temporariamente
        const div = document.createElement('div')
        div.innerHTML = termoHtml
        document.body.appendChild(div)

        // Configurações do PDF
        const options = {
          margin: [0.5, 0.5, 0.5, 0.5],
          filename: `${titulo}.pdf`,
          image: { type: 'jpeg', quality: 0.98 },
          html2canvas: { scale: 2 },
          jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
        }

        // Gerar o PDF a partir do HTML
        html2pdf()
          .from(div)
          .set(options)
          .save()
          .then(() => {
            // Remover o contêiner temporário do DOM após gerar o PDF
            document.body.removeChild(div)
          })
      },

      async enviarTermoSms() {
        const d = {
          status: 'Enviado',
          propostaId: this.parametro.id,
        }
        await useJwt.put('produto/termo/propostaAlterarTermos', d)
        this.termo.status = 'Enviado'

        const data = {
          telefone: this.parametro.segurado.tomador.telefone,
          msg: `Acesse o link a seguir para aceitar o termo de uso: ${this.termo.link}`,
        }

        await useJwt.post('produto/termo/enviarSms', data).then((res) => {
          this.$message.success('Mensagem enviada')
        })
      },
    },
  }
</script>
<style></style>
