<template>
  <b-form>
    <b-row>
      <b-table
        striped
        responsive
        class="position-relative"
        :per-page="perPageParcelas"
        :current-page="currentPageParcelas"
        :items="itemsParcelas"
        :fields="fieldsParcelas"
        :sort-by.sync="sortByParcelas"
        :sort-desc.sync="sortDescParcelas"
        :sort-direction="sortDirectionParcelas"
      >
        <template #cell(valor)="row">
          {{ formatarValor(row.item.valor) }}
        </template>
      </b-table>
      <b-card-body class="d-flex justify-content-center flex-wrap pt-0">
        <b-pagination
          v-model="currentPageParcelas"
          :total-rows="totalRowsParcelas"
          :per-page="perPageParcelas"
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </b-card-body>
    </b-row>
  </b-form>
</template>
<script>
  import Ripple from 'vue-ripple-directive'
  import { formatarValor, formatarData } from '@/libs/utils'
  import useJwt from '@/auth/jwt/useJwt'

  export default {
    components: {
      formatarData,
    },
    directives: {
      Ripple,
    },
    props: {
      parametro: {},
    },
    data() {
      return {
        formatarValor,
        formatarData,
        sortByParcelas: '',
        sortDescParcelas: false,
        sortDirectionParcelas: 'asc',
        perPageParcelas: 12,
        currentPageParcelas: 1,
        fieldsParcelas: ['parcela', 'valor', 'vencimento'],
        totalRowsParcelas: 0,
        itemsParcelas: [],
      }
    },
    methods: {
      gerarParcelas(tomadorSelecionado) {
        useJwt
          .post('utils/calcularParcelasTomador', {
            idTomador: this.parametro.tomadorSelecionado.id,
            institutoSelecionado: this.parametro.institutoSelecionado,
            parcelas: this.parametro.parcelas,
            taxa: this.parametro.taxa.toString(),
            valor: this.parametro.valor,
            tipoCalculo: this.parametro.tipoCalculo,
          })
          .then((response) => {
            this.itemsParcelas = response.data
            this.totalRowsParcelas = response.data.length
          })
          .catch((error) => {
            console.error(error)
          })
      },
    },
    watch: {
      ['parametro'](value, oldValue) {
        this.gerarParcelas()
      },
    },
  }
</script>
