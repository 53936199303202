<template>
  <div>
    <b-row id="linhaIndicadores">
      <b-col sm="12" md="3">
        <b-card>
          <CardIndicador
            id="margemEfetivo"
            :isLoading="isLoadingIndicadores"
            title="Valor Médio por Proposta"
            :total="chartValorMedio"
            :series="hardcodedSeries([10, 12, 14])"
          />
        </b-card>
      </b-col>
      <b-col sm="12" md="3">
        <b-card>
          <CardIndicador
            id="margemEfetivo"
            :isLoading="isLoadingIndicadores"
            title="Propostas Pendentes"
            :total="chartPendentes"
            :series="hardcodedSeries([10, 12, 14])"
            barColor="#CDDC39"
          />
        </b-card>
      </b-col>
      <b-col sm="12" md="3">
        <b-card>
          <CardIndicador
            id="margemEfetivo"
            :isLoading="isLoadingIndicadores"
            title="Propostas Aprovadas"
            :total="chartAprovadas"
            :series="hardcodedSeries([10, 12, 14])"
            barColor="#689F38"
          />
        </b-card>
      </b-col>
      <b-col sm="12" md="3">
        <b-card>
          <CardIndicador
            id="margemEfetivo"
            :isLoading="isLoadingIndicadores"
            title="Propostas Recusadas"
            :total="chartRecusadas"
            :series="hardcodedSeries([10, 12, 14])"
            barColor="#E91E63"
          />
        </b-card>
      </b-col>
    </b-row>
    <b-card-code no-body>
      <b-card-body>
        <b-row v-if="this.acesso.PropostaConsulta">
          <b-col md="2">
            <b-form-group label="Pesquisar Por" label-for="opcao">
              <b-form-select id="opcao" :options="tipoOpcoes" v-model="pesquisar.tipoOpcoes" />
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group label="Status" label-for="status">
              <b-form-select id="status" :options="statusOpcoes" v-model="pesquisar.statusOpcoes" />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Descrição" label-for="descricao">
              <b-form-input
                id="descricao"
                placeholder="Descrição"
                v-model="pesquisar.descricao"
                v-on:keydown.enter="carregarGrid"
              />
            </b-form-group>
          </b-col>
          <b-col md="auto">
            <b-button
              :disabled="isBusy"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              style="margin-top: 21px; width: 160px"
              @click="carregarGrid()"
            >
              {{ !isBusy ? 'Pesquisar' : '' }}
              <b-spinner small v-if="isBusy" />
            </b-button>
          </b-col>
          <b-col md="auto" align="right" v-show="this.acesso.PropostaCriarNovo">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              style="margin-top: 21px; width: 160px"
              @click="novaProposta()"
            >
              Nova Simulação
            </b-button>
          </b-col>
        </b-row>

        <b-card class="text-center" v-else>
          <b-card-body>
            <b-icon icon="exclamation-triangle" variant="danger" font-scale="2"></b-icon>
            <b-card-title class="text-danger mt-2">Acesso Negado!</b-card-title>
            <b-card-text class="mb-1"> Parece que seu usuário não tem permissão para realizar pesquisas. </b-card-text>
            <b-card-text class="mb-1">
              Para obter acesso, por favor, solicite assistência ou permissões adicionais ao administrador do sistema.
            </b-card-text>
          </b-card-body>
        </b-card>

        <b-row>
          <b-col md="3" align="right" v-show="!this.acesso.PropostaConsulta && this.acesso.PropostaCriarNovo">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              style="margin-top: 21px; width: 160px"
              @click="novaProposta()"
            >
              Nova Simulação
            </b-button>
          </b-col>
        </b-row>
      </b-card-body>

      <b-table
        v-show="items.length > 0"
        class="position-relative"
        :per-page="pesquisar.perPage"
        :items="items"
        :fields="fields"
        :current-page="pesquisar.currentPage"
        :sort-by.sync="sortByPropostas"
        :sort-desc.sync="sortDescPropostas"
        :sort-direction="sortDirectionPropostas"
        :busy="isBusy"
      >
        <template v-slot:table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle mr-1"></b-spinner>
            <strong>Carregando Proposta(s)...</strong>
          </div>
        </template>
        <template #cell(opcoes)="row">
          <b-dropdown variant="link" no-caret toggle-class="p-0" left :popper-opts="{ positionFixed: true }">
            <template #button-content>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="btn-icon"
                title="Este botão contém as opções disponíveis para este registro"
              >
                <feather-icon icon="SettingsIcon" />
              </b-button>
            </template>

            <b-dropdown-item @click="visualizarProposta(row.item)">
              <feather-icon icon="EyeIcon" size="16" />
              <span class="align-middle ml-50">Visualizar Proposta</span>
            </b-dropdown-item>

            <b-dropdown-item @click="gerarRelatorioProposta(row.item)">
              <feather-icon icon="EyeIcon" size="16" />
              <span class="align-middle ml-50">Relatório da Proposta</span>
            </b-dropdown-item>

            <b-dropdown-item
              @click="gerarContrato(row.item)"
              v-if="row.item.statusProposta == 'Aprovado'"
              v-show="acesso.PropostaGerarContrato"
            >
              <feather-icon icon="CheckSquareIcon" size="16" />
              <span class="align-middle ml-50">Gerar Contrato</span>
            </b-dropdown-item>

            <b-dropdown-item @click="visualizarHistorico(row.item)">
              <feather-icon icon="CheckSquareIcon" size="16" />
              <span class="align-middle ml-50">Histórico de Propostas do Segurado</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
        <template #cell(codigo)="row">
          {{ formatarCodigoSequencial(row.item.codigo) }}
        </template>
        <template #cell(nome)="row">
          {{ row.item.segurado.tomador.nomeCompleto }}
        </template>
        <template #cell(matricula)="row">
          {{ row.item.segurado.matricula }}
        </template>
        <template #cell(valorPrimeiraParcela)="row"> R$ {{ row.item.valorPrimeiraParcela }} </template>
        <template #cell(parcelas)="row">
          {{ row.item.parcelas }}
        </template>
        <template #cell(emissao)="row">
          {{ formatarData(row.item.emissao) }}
        </template>
        <template #cell(statusProposta)="row">
          <b-badge pill :variant="status[0][row.item.statusProposta]">
            {{ formatarStatus(row.item.statusProposta) }}
          </b-badge>
        </template>
      </b-table>
      <b-card-body v-if="items.length > 0" class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <b-form-group
          label="Por Página"
          style="align-items: center"
          label-cols="6"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select id="perPageSelect" v-model="pesquisar.perPage" size="sm" inline :options="pageOptions" />
        </b-form-group>

        <!-- pagination -->
        <b-pagination
          v-model="pesquisar.currentPage"
          :total-rows="totalRows"
          :per-page="pesquisar.perPage"
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </b-card-body>

      <b-modal
        id="modal-proposta"
        ref="modal-proposta"
        centered
        size="lg"
        title="Nova Simulação"
        no-close-on-backdrop
        hide-footer
        no-close-on-esc
      >
        <validation-observer ref="formProposta">
          <gerar-simulacao-proposta
            ref="propostaRef"
            @closeSimulationModal="fecharProposta"
            @atualizarGrid="carregarGrid"
            :parametro="parametroSimulacaoProposta"
          />
        </validation-observer>
      </b-modal>
      <b-modal
        id="modal-parcela"
        ref="modal-parcela"
        centered
        title="Demonstrativo das Parcelas"
        ok-title="Fechar"
        ok-only
        ok-variant="outline-secondary"
        no-close-on-backdrop
      >
        <parcela-simulacao-proposta :parametro="parametroParcela" />
      </b-modal>
      <b-modal
        id="modal-parametro-documento"
        ref="modal-parametro-documento"
        centered
        title="Modelo(s) de minuta cadastrado(s)"
        no-close-on-backdrop
        hide-footer
      >
        <b-row>
          <b-col md="12">
            <b-table
              hover
              responsive
              class="position-relative"
              :items="itemsModeloDocumento"
              :fields="fieldsModelo"
              thead-class="d-none"
              @row-clicked="myRowClickHandler"
            >
              <template #cell(nome)="row">
                <b class="text-primary">{{ row.item.nome.toLowerCase() }}</b>
              </template>
            </b-table>
          </b-col>
        </b-row>
      </b-modal>

      <b-modal id="modal-geraDoc" ref="modal-geraDoc" centered no-close-on-backdrop hide-footer hide-header>
        <contratoGeraContrato @contratoEvent="contratoMethod" :parametro="geraContrato" />
      </b-modal>

      <!-- INICIO DO VERIFICA SENHA -->
      <b-modal id="modal-auth-senha" ref="modal-auth-senha" centered size="sm" title="Autenticação" hide-footer>
        <AutenticaSenha @autenticarEvent="autenticarMethod" />
      </b-modal>

      <b-modal
        no-enforce-focus
        :auto-focus="false"
        id="modal-visualizar"
        ref="modal-visualizar"
        centered
        size="xl"
        title="Detalhes da Simulação"
        hide-footer
        no-close-on-backdrop
        ok-only
      >
        <visualizar-simulacao-proposta
          ref="simulacaoRef"
          @reloadComponent="reloadVisualizarProposta"
          @abreModalContrato="abreModalContrato"
          :parametro="visualizarSelecionada"
        />
      </b-modal>

      <b-modal
        id="modal-historico"
        ref="modal-historico"
        centered
        size="xl"
        title="Histórico de Propostas do Segurado"
        hide-footer
        no-close-on-backdrop
        ok-only
      >
        <visualizar-historico-proposta :seguradoId="seguradoSelecionado" />
      </b-modal>

      <relatorio-proposta-simples
        @mounted="gerarRelatorio"
        v-if="showRelatorio"
        ref="relatorioPropostaSimples"
        :parametro="visualizarSelecionada"
      />

      <!-- Contrato -->
      <b-modal id="modal-contrato" ref="modal-contrato" centered size="xl" hide-footer>
        <contrato-contrato @reloadComponent="reloadContratoContrato" v-if="contrato" :parametro="contrato" />
      </b-modal>
    </b-card-code>
  </div>
</template>

<script>
  import useJwt from '@/auth/jwt/useJwt'
  import { detectarNavegador, formatarCodigoSequencial, formatarData, formatarValor, isNumber } from '@/libs/utils'
  import AutenticaSenha from '@/views/pages/auth/autenticaSenha.vue'
  import relatorioPropostaSimples from '@/views/pages/proposta/relatorio/relatorioPropostaSimples.vue'
  import BCardCode from '@core/components/b-card-code/BCardCode.vue'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import { required } from '@validations'
  import { ValidationObserver, ValidationProvider } from 'vee-validate'
  import VueApexCharts from 'vue-apexcharts'
  import Ripple from 'vue-ripple-directive'
  import vSelect from 'vue-select'
  import GerarSimulacaoProposta from './components/gerarSimulacaoProposta.vue'
  import ParcelaSimulacaoProposta from './components/parcelaSimulacaoProposta.vue'
  import visualizarHistoricoProposta from './components/visualizarHistoricoProposta.vue'
  import VisualizarSimulacaoProposta from './components/visualizarSimulacaoProposta.vue'
  import ContratoContrato from './pages/financeiro/contrato/contratoContrato.vue'
  import contratoGeraContrato from './pages/financeiro/contrato/contratoGeraContrato.vue'

  var cardIndicadorMinHeight = 0

  const CardIndicador = {
    props: {
      isLoading: {
        type: Boolean,
        default: false,
      },
      title: {
        type: String,
      },
      total: {
        type: String,
        default: 'R$ 0,00',
      },
      series: {
        type: Array,
        default: null,
      },
      barColor: {
        type: String,
        default: '#2196F3',
      },
    },
    components: {
      VueApexCharts,
    },
    data() {
      return {
        nomeRelatorio: { nome: 'Relatório de Proposta' },

        chartOptions: {
          chart: {
            type: 'bar',
            toolbar: {
              show: false,
            },
          },
          grid: {
            show: false,
            padding: {
              left: 0,
              right: 0,
              top: -15,
              bottom: -15,
            },
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '45%',
              startingShape: 'rounded',
              endingShape: 'rounded',
              borderRadius: 5,
              borderRadiusApplication: 'around',
            },
          },
          legend: {
            show: false,
          },
          dataLabels: {
            enabled: false,
          },
          colors: [this.barColor],
          xaxis: {
            labels: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
          },
          yaxis: {
            show: false,
          },
          tooltip: {
            enabled: false,
            x: { show: false },
            y: { show: false },
          },
        },
      }
    },
    async mounted() {
      this.checkTitleHeight()
    },
    methods: {
      boldRowClass(item, type) {
        return item.negrito ? 'font-weight-bold' : ''
      },
      checkTitleHeight() {
        if (this.$refs.title.offsetHeight > cardIndicadorMinHeight) {
          cardIndicadorMinHeight = this.$refs.title.offsetHeight
        }
      },
    },
    render() {
      return (
        <b-card no-body>
          <b-card-body class="pb-0">
            <strong class="d-inline-block" id="title" ref="title" style={`min-height:${cardIndicadorMinHeight}px`}>
              {this.title}
            </strong>
            {this.isLoading && <b-spinner big class="d-block mt-1 mb-2" />}
            {!this.isLoading && (
              <div style="position: relative;">
                <h1 class="font-weight-bolder mb-0 py-1" style="z-index:2;">
                  {this.total}
                </h1>
                <div style="position: absolute; right: 0; bottom:0; width: 70px; opacity: .9">
                  <vue-apex-charts height="50" options={this.chartOptions} series={this.series} />
                </div>
              </div>
            )}
          </b-card-body>
        </b-card>
      )
    },
  }
  export default {
    components: {
      contratoGeraContrato,
      BCardCode,
      ValidationProvider,
      ValidationObserver,
      visualizarHistoricoProposta,
      vSelect,
      AutenticaSenha,
      GerarSimulacaoProposta,
      VisualizarSimulacaoProposta,
      ParcelaSimulacaoProposta,
      formatarData,
      formatarCodigoSequencial,
      relatorioPropostaSimples,
      ContratoContrato,
      CardIndicador,
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        isBusy: false,
        parametroSimulacaoProposta: { origem: 'Administração' },
        propostaSelecionada: {},
        parametroParcela: {},
        visualizarSelecionada: { tomador: {} },
        isNumber,
        formatarValor,
        formatarData,
        formatarCodigoSequencial,
        loading: false,
        showRelatorio: false,
        seguradoSelecionado: undefined,
        seguradoNome: undefined,
        seguradoMatricula: undefined,
        pesquisar: {
          tipoOpcoes: 1,
          statusOpcoes: '',
          descricao: '',
          perPage: 10,
          currentPage: 1,
        },
        carregando: false,
        required,
        tipoOpcoes: [
          { value: 1, text: 'Nome' },
          { value: 2, text: 'Matrícula' },
          { value: 3, text: 'Código' },
        ],
        statusOpcoes: [
          { value: '', text: 'Pendente/Aprovado' },
          { value: 'Aprovado', text: 'Aprovado' },
          { value: 'Pendente', text: 'Pendente' },
          { value: 'Negado', text: 'Negado' },
          { value: 'ContratoGerado', text: 'Contrato Gerado' },
          { value: 'ContratoCancelado', text: 'Contrato Cancelado' },
          { value: 'Expirado', text: 'Contrato Expirado' },
        ],
        options: {
          cleaveCPF: {
            delimiters: ['.', '.', '-'],
            blocks: [3, 3, 3, 2],
            numericOnly: true,
          },
          cleaveDate: {
            date: true,
            delimiter: '/',
            datePattern: ['d', 'm', 'Y'],
          },
        },
        perPageParcelas: 12,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPageParcelas: 1,
        sortBy: '',
        sortByPropostas: '',
        sortDesc: false,
        sortDescPropostas: false,
        sortDirection: 'asc',
        sortDirectionPropostas: 'asc',
        fields: [
          { key: 'opcoes', label: 'Opções' },
          { key: 'codigo', label: 'Código', sortable: true },
          { key: 'nome', label: 'Nome' },
          { key: 'matricula', label: 'Matrícula' },
          {
            key: 'valorComIOF',
            label: 'Valor do Contrato',
            sortable: true,
            formatter: (value) => {
              if (typeof value === 'string' && value.includes('R$')) {
                return value
              } else {
                return formatarValor(value)
              }
            },
          },
          {
            key: 'valorBaseCalculo',
            label: 'Valor da Proposta',
            sortable: true,
            formatter: (value) => {
              if (typeof value === 'string' && value.includes('R$')) {
                return value
              } else {
                return formatarValor(value)
              }
            },
          },
          {
            key: 'emissao',
            label: 'Data da Proposta',
            formatter: (value, key, item) => {
              return formatarData(value)
            },
            sortable: true,
          },
          { key: 'statusProposta', label: 'Status', sortable: true },
        ],
        fieldsParcelas: ['parcela', 'valor', 'vencimento'],
        items: [],
        totalRowsParcelas: 24,
        status: [
          {
            Aprovado: 'success',
            Pendente: 'warning',
            Negado: 'danger',
            ContratoCancelado: 'danger',
            Expirado: 'secondary',
            ContratoGerado: 'info',
            BloqueadoAprovado: 'danger',
            BloqueadoPendente: 'danger',
          },
        ],
        acesso: {
          PropostaConsulta: false,
          PropostaCriarNovo: false,
          PropostaGerarContrato: false,
          PropostaAprovarProposta: false,
        },
        itemsModeloDocumento: undefined,
        fieldsModelo: [{ key: 'nome', label: 'Documento Modelo' }],
        geraContrato: {},
        contratoId: undefined,
        propostaId: undefined,
        contrato: {},
        chartPendentes: '0',
        chartAprovadas: '0',
        chartRecusadas: '0',
        chartValorMedio: 'R$ 0,00',
        isLoadingIndicadores: false,
      }
    },
    computed: {
      sortOptions() {
        return this.fields.filter((f) => f.sortable).map((f) => ({ text: f.label, value: f.key }))
      },
    },
    async mounted() {
      this.carregaIndicadores()
      if (this.userData.role == 'master') {
        this.acesso.PropostaConsulta = true
        this.acesso.PropostaCriarNovo = true
        this.acesso.PropostaGerarContrato = true
        this.acesso.PropostaAprovarProposta = true
      } else {
        this.acesso.PropostaConsulta = this.userData.GrupoAcesso.PropostaConsulta
        this.acesso.PropostaCriarNovo = this.userData.GrupoAcesso.PropostaCriarNovo
        this.acesso.PropostaGerarContrato = this.userData.GrupoAcesso.PropostaGerarContrato
        this.acesso.PropostaAprovarProposta = this.userData.GrupoAcesso.PropostaAprovarProposta
      }
      await this.atualizarIndices()
    },
    methods: {
      formatarStatus(status) {
        switch (status) {
          case 'ContratoCancelado':
            return 'Contrato Cancelado'
          case 'ContratoGerado':
            return 'Contrato Gerado'
          case 'BloqueadoAprovado':
            return 'Suspensão Judicial'
          case 'BloqueadoPendente':
            return 'Suspensão Judicial'
          default:
            return status
        }
      },
      visualizarHistorico(item) {
        this.seguradoSelecionado = item.segurado.id
        this.seguradoNome = item.segurado.tomador.nomeCompleto
        this.seguradoMatricula = item.segurado.matricula

        this.$refs['modal-historico'].show()
      },
      contratoMethod(data, msg) {
        if (data == 'error') {
          this.$refs['modal-geraDoc'].hide()
          this.$refs['modal-parametro-documento'].hide()
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: msg,
                icon: 'error',
                variant: 'danger',
              },
            },
            {
              position: 'top-right',
              timeout: 8000,
            },
          )
        } else if (data) {
          this.$refs['modal-geraDoc'].hide()
          this.$refs['modal-parametro-documento'].hide()
        }

        this.carregarGrid()
      },
      myRowClickHandler(row, index) {
        this.downloadModelo(row.id)
      },
      async gerarContrato(proposta) {
        const { data } = await useJwt.post('financeiro/GetContratoPelaProposta', { id: proposta.id })
        this.abreModalContrato(data)
      },
      reloadContratoContrato(itemId) {
        this.$refs['modal-contrato'].hide()
        this.carregarGrid()
      },
      reloadVisualizarProposta() {
        this.$refs['modal-visualizar'].hide()
        this.carregarGrid()
      },
      async gerarRelatorio() {
        await this.$refs.relatorioPropostaSimples.exportPDF()
      },
      autenticarMethod(data) {
        if (data) {
          const id = this.userData.id

          let navegadorUtilizado = detectarNavegador()
          useJwt
            .pesquisar('proposta/aprovarProposta', {
              idProposta: this.propostaSelecionada,
              usuario: id,
              navegadorUtilizado,
            })
            .then((response) => {
              this.$refs['modal-auth-senha'].hide()
              this.items.filter((item) => {
                if (item.id == this.propostaSelecionada) item.statusProposta = 'Aprovado'
              })
            })
            .catch((error) => {
              console.error(error)
            })
        }
      },
      carregarGrid() {
        this.isBusy = true
        useJwt
          .pesquisar('proposta/Getpesquisar', {
            ...this.pesquisar,
          })
          .then((response) => {
            this.isBusy = false
            this.items = response.data.dados
            this.totalRows = response.data.total
            this.pesquisar = { ...this.pesquisar, currentPage: 1 }
            this.verificaStatusPropostas()
          })
          .catch((error) => {
            console.error(error)
          })
      },
      async verificaStatusPropostas() {
        try {
          //Monta competências para buscar
          let competenciaAtual = ''
          let competenciaAnterior = ''
          let dataAtual = new Date()
          competenciaAtual = String(dataAtual.getFullYear()) + String(dataAtual.getMonth() + 1).padStart(2, '0')

          dataAtual.setMonth(dataAtual.getMonth() - 1)
          competenciaAnterior = String(dataAtual.getFullYear()) + String(dataAtual.getMonth() + 1).padStart(2, '0')

          // Busca qual índice está parametrizado
          let tipoIndice
          await useJwt
            .post('cadastro/instituto/GetInstitutoIndice', { institutoId: this.userData.institutoSelecionado })
            .then((response) => {
              tipoIndice = response.data.tipoIndiceSelecionado
            })

          // Realiza consulta no IBGE
          let url
          if (tipoIndice == 'IPCA')
            url = `https://servicodados.ibge.gov.br/api/v3/agregados/7060/periodos/${competenciaAtual}/variaveis/63|69?localidades=N1[all]&classificacao=315[7169]`
          else
            url = `https://servicodados.ibge.gov.br/api/v3/agregados/7063/periodos/${competenciaAtual}/variaveis/44|68?localidades=N1[all]&classificacao=315[7169]`

          // Busca dados na api do IBGE
          let response = await useJwt.get(url)

          // Quando for maior que 1 irá pegar a competência atual
          if (response?.data?.length > 1) {
            competenciaAtual = String(dataAtual.getFullYear()) + String(dataAtual.getMonth() + 1).padStart(2, '0')
          } else {
            // Quando for igual a 1 irá pegar a competência anterior
            dataAtual.setMonth(dataAtual.getMonth() - 1)
            competenciaAtual = String(dataAtual.getFullYear()) + String(dataAtual.getMonth() + 1).padStart(2, '0')
          }
        } catch (error) {
          console.error(error)
        }
      },
      novaProposta() {
        this.$refs['modal-proposta'].show()
      },
      fecharProposta() {
        this.$refs['modal-proposta'].hide()
      },
      parcelas(bvModalEvt) {
        bvModalEvt.preventDefault()
        this.$refs.formProposta.validate().then((success) => {
          if (success) {
            this.parametroParcela = this.$refs.propostaRef.$data.proposta
            this.$refs['modal-parcela'].show()
          }
        })
      },
      visualizarProposta(item) {
        this.visualizarSelecionada = {
          acesso: {
            ...this.acesso,
          },
          ...item,
          valor: formatarValor(item.valor),
          valorPrimeiraParcela: String(item.valorPrimeiraParcela).includes('R$')
            ? item.valorPrimeiraParcela
            : formatarValor(item.valorPrimeiraParcela),
          primeiraParcela: formatarData(item.primeiraParcela),
          origem: 'Proposta',
        }
        this.$refs['modal-visualizar'].show()
      },
      gerarRelatorioProposta(item) {
        if (item.valorBaseCalculo) {
          item.valorBaseCalculo = item.valorBaseCalculo.includes('R$')
            ? item.valorBaseCalculo
            : formatarValor(item.valorBaseCalculo)
        }
        if (item.valorPrimeiraParcela) {
          item.valorPrimeiraParcela = formatarValor(item.valorPrimeiraParcela)
        }
        this.showRelatorio = true
        if (this.showRelatorio) {
          this.visualizarSelecionada = {
            ...item,
            primeiraParcela: formatarData(item.primeiraParcela),
            origem: 'Proposta',
          }
        }
        this.$nextTick(async () => {
          await this.$refs.relatorioPropostaSimples.exportPDF()
        })
      },
      negarProposta(item) {
        this.$swal({
          title: 'Atenção!',
          text: 'Deseja realmente NEGAR a proposta selecionado?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sim!',
          cancelButtonText: 'Não!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-danger ml-1',
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            const id = this.userData.id

            this.$swal({
              title: 'Observação',
              input: 'textarea',
              allowEscapeKey: false,
              customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-secondary ml-1',
              },
              buttonsStyling: false,
              inputAttributes: {
                autocapitalize: 'off',
                required: true,
              },
              inputValidator: (value) => {
                return !value && 'Por favor informe o motivo!'
              },
              showCancelButton: true,
              confirmButtonText: 'Confirmar',
              cancelButtonText: 'Fechar',
              showLoaderOnConfirm: true,
              preConfirm(motivo) {
                return useJwt
                  .pesquisar('proposta/negarProposta', {
                    idProposta: item,
                    usuario: id,
                    motivoNegado: motivo,
                  })
                  .then((response) => {})
                  .catch((error) => {
                    console.error(error)
                  })
              },
              allowOutsideClick: () => !this.$swal.isLoading(),
            }).then((result) => {
              if (result.value) {
                this.$swal({
                  title: 'Atenção!',
                  text: 'Proposta negado com sucesso',
                  icon: 'success',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                }).then((result) => {
                  if (result.value) {
                    this.items.filter((f) => {
                      if (f.id == item) f.statusProposta = 'Negado'
                    })
                  }
                })
              }
            })
          }
        })
      },
      aprovarProposta(item) {
        this.$swal({
          title: 'Atenção!',
          text: 'Deseja realmente APROVAR a proposta selecionada?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sim!',
          cancelButtonText: 'Não!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-danger ml-1',
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            this.propostaSelecionada = item
            if (this.userData.GrupoAcesso.FinanceiroAlterarAssinaturas == true || this.userData.role == 'master') {
              this.$refs['selecionarAssinaturas'].show()
            } else {
              this.$refs['modal-auth-senha'].show()
            }
          }
        })
      },
      abreModalContrato(contrato) {
        this.contrato = {
          ...contrato,
          createdAt: formatarData(contrato.createdAt),
          vencimento: formatarData(contrato.vencimento),
        }

        this.$refs['modal-visualizar'].hide()
        this.$refs['modal-contrato'].show()
      },
      carregaIndicadores() {
        this.isLoadingIndicadores = true

        useJwt
          .post('proposta/GetIndicadoresProposta')
          .then((result) => {
            this.chartPendentes = String(result.data.chartPendentes)
            this.chartAprovadas = String(result.data.chartAprovadas)
            this.chartRecusadas = String(result.data.chartRecusadas)
            this.chartValorMedio = result.data.chartValorMedio
            this.isLoadingIndicadores = false
          })
          .catch((error) => {
            console.error(error)
            this.isLoadingIndicadores = true
            this.$message.error('Erro ao consultar indicadores!')
          })
      },
      hardcodedSeries(data) {
        return [
          {
            name: '',
            data: data.map((d) => ({ x: '', y: d })),
          },
        ]
      },
      async atualizarIndices() {
        useJwt.post(`configuracoes/indices/PostAtualizaIndices`).catch((error) => {
          console.error(error)
        })
      },
    },
  }
</script>
