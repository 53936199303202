<script>
  import useJwt from '@/auth/jwt/useJwt'

  export default {
    props: {
      numeroGestores: {
        type: Number,
        default: 1,
      },
      numeroTestemunhas: {
        type: Number,
        default: 2,
      },
      gestoresSelecionados: {
        type: Array,
        default: () => [],
      },
      testemunhasSelecionadas: {
        type: Array,
        default: () => [],
      },
    },
    methods: {
      selection: function () {
        return {
          confirm: () => {
            this.$emit('confirm', {
              gestores: this.gestor.valor,
              testemunhas: this.testemunha.valor,
            })
          },
        }
      },
      usuarios: function () {
        return {
          listar: async () => {
            return (await useJwt.get('cadastro/user')).data
          },
        }
      },
    },
    data() {
      return {
        gestor: {
          valor: [],
          opcoes: [],
        },
        testemunha: {
          valor: [],
          opcoes: [],
        },
      }
    },
    async mounted() {
      const usuarios = await this.usuarios().listar()
      this.testemunha.opcoes = usuarios.filter((usuario) => usuario.tipoAssinatura === 'testemunha')
      this.gestor.opcoes = usuarios.filter((usuario) => usuario.tipoAssinatura === 'gestor')
      this.gestor.valor = this.gestoresSelecionados
      this.testemunha.valor = this.testemunhasSelecionadas
    },
  }
</script>

<template>
  <div class="tw-flex tw-flex-col tw-items-center tw-justify-center">
    <h4 class="tw-text-lg tw-font-medium tw-mb-2">Gestor</h4>
    <small class="text-danger" v-if="gestor.valor.length < numeroGestores"
      >Selecione no mínimo {{ numeroGestores }} {{ numeroGestores == 1 ? 'Gestor' : 'Gestores' }}</small
    >
    <v-select-pt
      v-model="gestor.valor"
      :options="gestor.opcoes"
      :selectable="() => gestor.valor.length < numeroGestores"
      multiple
      label="name"
      class="tw-w-full tw-max-w-md tw-mb-4 tw-rounded-md tw-shadow-sm tw-focus:outline-none tw-focus:ring-2 tw-focus:ring-blue-500 tw-focus:border-blue-500 tw-sm:text-sm"
    ></v-select-pt>
    <h4 class="tw-text-lg tw-font-medium tw-mb-2">Testemunhas</h4>
    <small class="text-danger" v-if="testemunha.valor.length < numeroTestemunhas"
      >Selecione no mínimo {{ numeroTestemunhas }} {{ numeroTestemunhas == 1 ? 'Testemunha' : 'Testemunhas' }}</small
    >
    <v-select-pt
      v-model="testemunha.valor"
      multiple
      :selectable="() => testemunha.valor.length < numeroTestemunhas"
      :options="testemunha.opcoes"
      label="name"
      class="tw-w-full tw-max-w-md tw-mb-4 tw-rounded-md tw-shadow-sm tw-focus:outline-none tw-focus:ring-2 tw-focus:ring-blue-500 tw-focus:border-blue-500 tw-sm:text-sm"
    ></v-select-pt>
    <b-button
      style="display: flex; flex-grow: 1; height: 40px; justify-content: center"
      :disabled="testemunha.valor.length < numeroTestemunhas || gestor.valor.length < numeroGestores"
      @click="selection().confirm()"
      class="btn btn-success tw-w-full tw-grow"
    >
      <feather-icon icon="CheckSquareIcon" size="16" />
      <span class="align-middle ml-50">Confirmar</span>
    </b-button>
  </div>
</template>

<style>
  /* @tailwind base; */
  @tailwind components;
  @tailwind utilities;
</style>
